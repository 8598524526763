<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import { onBeforeMount,ref, onMounted } from "vue";
import axios from "../axios";

import { useStore } from "vuex";
const store = useStore();
onBeforeMount(() => {
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
});

const statuscar = ref({
  data1: 0,
  data2: 0,
  data3: 0,
  data40: 0,
  data4: 0,

  data21: 0,
  data22: 0,
  data23: 0,
  data24: 0,

  data31: 0,
  data32: 0,
  data33: 0,
  data34: 0,

  data41: 0,
  data42: 0,
  data43: 0,
  data44: 0,
});

const chartcar = ref({
  date: [],
  value1: [],
  value2: []
});

const showChart = ref(false)
onMounted(async () => {
    const res = await axios.post(`getdashboard`);
    console.log(res)
    if (res.data.status == 1) {
      console.log(res.data)
      statuscar.value.data1 = res.data.data1.count;
      statuscar.value.data2 = res.data.data2.count;
      statuscar.value.data3 = res.data.data3.count;
      statuscar.value.data40 = res.data.data40.count;
      statuscar.value.data4 = res.data.data6;
      chartcar.value.date = res.data.date;
      chartcar.value.value1 = res.data.data4;
      chartcar.value.value2 = res.data.data5;
      showChart.value = true;

      statuscar.value.data21 = res.data.data21.count?res.data.data21.count:0;
      statuscar.value.data22 = res.data.data22.count?res.data.data22.count:0;
      statuscar.value.data23 = res.data.data23.count?res.data.data23.count:0;
      statuscar.value.data24 = res.data.data24.count?res.data.data24.count:0;

      statuscar.value.data31 = res.data.data31.count?res.data.data31.count:0;
      statuscar.value.data32 = res.data.data32.count?res.data.data32.count:0;
      statuscar.value.data33 = res.data.data33.count?res.data.data33.count:0;
      statuscar.value.data34 = res.data.data34.count?res.data.data34.count:0;

      statuscar.value.data41 = res.data.data41.count?res.data.data41.count:0;
      statuscar.value.data42 = res.data.data42.count?res.data.data42.count:0;
      statuscar.value.data43 = res.data.data43.count?res.data.data43.count:0;
      statuscar.value.data44 = res.data.data44.count?res.data.data44.count:0;
    }
})

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่ได้สิทธิส่วนลดทั้งหมด" :value="{
              text: statuscar.data1,
            }" description="<span
                class='text-sm font-weight-bolder text-dark'
                >100%</span> ของจำนวนทั้งหมด" :icon="{
              component: 'fas fa-truck',
              background: 'bg-gradient-primary',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่แจ้งเชื่อมต่อข้อมูล QR Code และ บัตรส่วนลด" :value="{
              text: statuscar.data2,
            }" description="<span
                class='text-sm font-weight-bolder text-dark'
                >100%</span> ของจำนวนทั้งหมด" :icon="{
              component: 'fas fa-truck',
              background: 'bg-gradient-danger',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่เชื่อมต่อข้อมูลสำเร็จ" :value="{
              text: statuscar.data22,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ (((statuscar.data22 / (statuscar.data21+statuscar.data22+statuscar.data23+statuscar.data24)) * 100).toFixed(2)) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-truck',
              background: 'bg-gradient-danger',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่อยู่ระหว่างดำเนินการ" :value="{
              text: statuscar.data21,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data21 / (statuscar.data21+statuscar.data22+statuscar.data23+statuscar.data24)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-truck',
              background: 'bg-gradient-danger',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่ขอข้อมูลเพิ่มเติม" :value="{
              text: statuscar.data23,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data23 / (statuscar.data21+statuscar.data22+statuscar.data23+statuscar.data24)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-truck',
              background: 'bg-gradient-danger',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่ไม่ได้อยู่ในโครงการบัตรส่วนลด" :value="{
              text: statuscar.data24,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data24 / (statuscar.data21+statuscar.data22+statuscar.data23+statuscar.data24)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-truck',
              background: 'bg-gradient-danger',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่แจ้งรอป้ายและป้ายแดง" :value="{
              text: statuscar.data3,
            }" description="<span
                class='text-sm font-weight-bolder text-dark'
                >100%</span> ของจำนวนทั้งหมด" :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-warning',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่เชื่อมต่อข้อมูลสำเร็จ" :value="{
              text: statuscar.data32,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data32 / (statuscar.data31+statuscar.data32+statuscar.data33+statuscar.data34)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-warning',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่อยู่ระหว่างดำเนินการ" :value="{
              text: statuscar.data31,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data31 / (statuscar.data31+statuscar.data32+statuscar.data33+statuscar.data34)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-warning',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่ขอข้อมูลเพิ่มเติม" :value="{
              text: statuscar.data33,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data33 / (statuscar.data31+statuscar.data32+statuscar.data33+statuscar.data34)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-warning',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่ไม่ใช่สถานะรอป้ายหรือป้ายแดง" :value="{
              text: statuscar.data34,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data34 / (statuscar.data31+statuscar.data32+statuscar.data33+statuscar.data34)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-warning',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่แจ้งเปลี่ยนทะเบียน" :value="{
              text: statuscar.data40,
            }" description="<span
                class='text-sm font-weight-bolder text-dark'
                >100%</span> ของจำนวนทั้งหมด" :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-info',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่เชื่อมต่อข้อมูลสำเร็จ" :value="{
              text: statuscar.data42,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data42 / (statuscar.data41+statuscar.data42+statuscar.data43+statuscar.data44)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-info',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่อยู่ระหว่างดำเนินการ" :value="{
              text: statuscar.data41,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data41 / (statuscar.data41+statuscar.data42+statuscar.data43+statuscar.data44)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-info',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่ขอข้อมูลเพิ่มเติม" :value="{
              text: statuscar.data43,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data43 / (statuscar.data41+statuscar.data42+statuscar.data43+statuscar.data44)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-info',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนรถที่มีสถานะเปลี่ยนป้ายไม่สำเร็จ" :value="{
              text: statuscar.data44,
            }" 
            :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statuscar.data44 / (statuscar.data41+statuscar.data42+statuscar.data43+statuscar.data44)) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
                :icon="{
              component: 'fas fa-car-alt',
              background: 'bg-gradient-info',
              shape: 'rounded-circle',
            }" />
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <mini-statistics-card title="จำนวนเครดิต SMS คงเหลือ" :value="{
              text: statuscar.data4,
            }" description="<span
                class='text-sm font-weight-bolder text-dark'
                >100%</span> ของจำนวนทั้งหมด" :icon="{
              component: 'fas fa-sms',
              background: 'bg-gradient-success',
              shape: 'rounded-circle',
            }" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line"
                v-if="showChart"
                title="จำนวนรถที่แจ้งเพิ่มเข้าในระบบของแต่ละวัน"
                description="โดยจะมีทั้งหมด 2 เส้นคือ เส้นสีดำคือจำนวนรถแจ้งแก้ไขข้อมูลรถรอป้าย/ป้ายแดง ส่วนเส้นที่ฟ้าคือรถที่แจ้งสิทธิส่วนลดตกหล่น"
                :chart="{
              labels: chartcar.date,
              datasets: [
                {
                  data1: chartcar.value1,
                  data2: chartcar.value2,
                },
              ],
            }" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
