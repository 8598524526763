<script setup >
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import { ref, onMounted, reactive, inject } from "vue";
import axios from "../axios";

const Swal = inject('$swal')

import { useStore } from "vuex";
const store = useStore();

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-responsive-bs5';
import 'datatables.net-select-bs5';
import dataTextTH from '../assets/translate/datatable-th.json'

DataTable.use(DataTablesLib);
DataTablesLib.Buttons.jszip(jszip);
DataTablesLib.Buttons.pdfMake(pdfmake);

import { Modal } from "bootstrap";

const statususer = ref([]);
const datauser = ref([]);
const users = ref({});
const users_edit = ref({});
const dataselect = ref();

const state = reactive({
    modal_IN: null,
})
async function getdata_user(){
  const res = await axios.get(`users`);
  if(res.data.status == 1){
    let uc1 = 0,uc2 = 0,uc3 = 0,uc4 = 0 ;
    for (let i = 0; i < res.data.datas.length; i++) {
      if(res.data.datas[i].type == 1) uc1++; else uc2++;
      if(res.data.datas[i].status == 1) uc3++; else uc4++;
      datauser.value.push([res.data.datas[i].id.split(':')[1],res.data.datas[i].name,res.data.datas[i].username,new Date(res.data.datas[i].insert_at).toLocaleString("en-GB").replace( /,/,"" ),res.data.datas[i].status == 2?'ระงับการใช้งาน':'ใช้งานได้ปกติ',res.data.datas[i].type == 2?'ซุปเปอร์ แอดมิน':'แอดมิน']);
    }
    statususer.value = [uc1,uc2,uc3,uc4];
  }
}

onMounted(() => {
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  state.modal_IN = new Modal('#model-in', {})
  state.modal_ED = new Modal('#model-ed', {})
  getdata_user();
  dataselect.value.dt.on('click', 'button', function (e) {
    let data = dataselect.value.dt.row(e.target.closest('tr')).data();
    get_edit_data(data)
  })
})


function openModalIN(){ state.modal_IN.show()}
function closeModalIN(){state.modal_IN.hide()}
function openModalED(){ state.modal_ED.show()}
function closeModalED(){state.modal_ED.hide()}

async function add_data(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  if(!users.value.username){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกชื่อผู้ใช้งาน(Username)",
        icon: "warning"
    });
  }else if(!users.value.password){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกรหัสผ่าน(Password)",
        icon: "warning"
    });
  }else if(!users.value.name){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกชื่อ",
        icon: "warning"
    });
  }else if(!users.value.type){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกประเภทผู้ใช้งาน",
        icon: "warning"
    });
  }else if(!users.value.status){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกสถานะผู้ใช้งาน",
        icon: "warning"
    });
  }else{
    const res = await axios.post(`insertuser`,{
        username: users.value.username,
        password: users.value.password,
        name: users.value.name,
        type: users.value.type,
        status: users.value.status,
    });
    if(res.data.status == 1){
      closeModalIN();
      datauser.value = [];
      statususer.value = [];
      getdata_user();
      users.value.username = '';
      users.value.password = '';
      users.value.name = '';
      users.value.type = '';
      users.value.status = '';
      Swal.fire({
          title: 'สำเร็จ',
          text: "เพิ่มข้อมูลผู้ใช้งานเรียบร้อยแล้ว",
          icon: "success"
      });
    }else if(res.data.status == 2){
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "ชื่อผุ้ใช้งานซ้ำกรุณาเปลี่ยนชื่อผุ้ใช้งาน",
          icon: "error"
      });
    }else{
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "เพิ่มข้อมูลไม่สำเร็จ",
          icon: "error"
      });
    }
  }
}

function get_edit_data(data){
  users_edit.value.id = data[0];
  users_edit.value.username = data[2];
  users_edit.value.name = data[1];
  users_edit.value.type = data[5] == 'แอดมิน'? "1": "2";
  users_edit.value.status = data[4] == 'ใช้งานได้ปกติ'? "1": "2";
  openModalED()
}

async function edit_data(){
  Swal.fire({
    title: 'ระบบกำลังทำงาน',
    html: 'กรุณารอสักครู่ ...',
    allowEscapeKey: false,
    allowOutsideClick: false,
  });
  Swal.showLoading()
  if(!users_edit.value.username){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกชื่อผู้ใช้งาน(Username)",
        icon: "warning"
    });
  }else if(!users_edit.value.name){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณากรอกชื่อ",
        icon: "warning"
    });
  }else if(!users_edit.value.type){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกประเภทผู้ใช้งาน",
        icon: "warning"
    });
  }else if(!users_edit.value.status){
    Swal.fire({
        title: 'ตำเตือน',
        text: "กรุณาเลือกสถานะผู้ใช้งาน",
        icon: "warning"
    });
  }else{
    const res = await axios.post(`edituser`,{
      username: users_edit.value.username,
      password: users_edit.value.password,
      name: users_edit.value.name,
      type: users_edit.value.type,
      status: users_edit.value.status,
      id: users_edit.value.id,
    });
    if(res.data.status == 1){
      closeModalED();
      datauser.value = [];
      statususer.value = [];
      getdata_user();
      users_edit.value.id = '';
      users_edit.value.username = '';
      users_edit.value.password = '';
      users_edit.value.name = '';
      users_edit.value.type = '';
      users_edit.value.status = '';
      Swal.fire({
          title: 'สำเร็จ',
          text: "แก้ไขข้อมูลผู้ใช้งานเรียบร้อยแล้ว",
          icon: "success"
      });
    }else if(res.data.status == 2){
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "ชื่อผุ้ใช้งานซ้ำกรุณาเปลี่ยนชื่อผุ้ใช้งาน",
          icon: "error"
      });
    }else{
      Swal.fire({
          title: 'เกิดข้อผิดพลาด',
          text: "แก้ไขข้อมูลไม่สำเร็จ",
          icon: "error"
      });
    }
  }
  closeModalED()
}

function del_data(){
  if(dataselect.value.dt.rows({ selected: true }).count() >= 1){
    Swal.fire({
      title: "คำเตือน",
      text: "ต้องการลบข้อมูลที่เลือกหรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ต้องการลบ"
    }).then( async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'ระบบกำลังทำงาน',
          html: 'กรุณารอสักครู่ ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        Swal.showLoading()
        let dataselectf = dataselect.value.dt.rows({ selected: true }).data();
        var idarray = [];
        for (let index = 0; index < dataselectf.length; index++) {
          idarray.push("'"+dataselectf[index][0]+"'");
        }
        const res = await axios.post(`del/user`,{
          idarray: idarray,
        });
        if(res.data.status == 1){
          datauser.value = [];
          statususer.value = [];
          getdata_user();
          Swal.fire({
            title: "สำเร็จ",
            text: "ข้อมูลถูกลบเรียบร้อยแล้ว",
            icon: "success"
          });
        }else{
          Swal.fire({
            title: "ไม่สำเร็จ",
            text: "เกิดข้อผิดพลาด",
            icon: "error"
          });
        }
      }
    });
  }else{
    Swal.fire({
      title: "คำเตือน",
      text: "กรุณาเลือกข้อมูลที่ต้องการลบในตารางก่อน !",
      icon: "error"
    });
  }
}



const options = ref({
  dom: '<"top"<"left-col"B><"center-col"l><"right-col"f>t<"table-center"i>p',
  buttons: [
      {
          extend: 'copyHtml5',
          text: '<i class="fas fa-file-alt text-lg"></i>',
          titleAttr: 'Copy'
      },
      {
          extend: 'excelHtml5',
          text: '<i class="fas fa-file-excel text-lg"></i>',
          titleAttr: 'Excel'
      },
      {
          extend: 'csvHtml5',
          text: '<i class="fas fa-file-csv text-lg"></i>',
          titleAttr: 'CSV'
      },
      {
          extend: 'pdfHtml5',
          text: '<i class="fas fa-file-pdf text-lg"></i>',
          titleAttr: 'PDF'
      }
  ],
  pageLength: 10,
  responsive: true,
  select: {
        style: 'multi',
        selector: 'td:not(:nth-child(7))',
    },
  lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"] ],
  order: [[3, 'desc']],
  language: dataTextTH,
  columnDefs: [
        {
            data: null,
            defaultContent: '<button type="button" class="btn btn-warning btn-sm"">แก้ไข</button>',
            targets: -1
        }
    ]
});
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนแอดมินทั้งหมด"
              :value= "{
                text: statususer[0],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statususer[0] / (statususer[0]+statususer[1])) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-user-nurse',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนซุปเปอร์แอดมินทั้งหมด"
              :value= "{
                text: statususer[1],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statususer[1] / (statususer[0]+statususer[1])) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-user-md',
                background: 'bg-gradient-info',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนผู้ใช้งานที่มีสถานะใช้งานได้"
              :value= "{
                text: statususer[2],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statususer[2] / (statususer[2]+statususer[3])) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-user-check',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="จำนวนผู้ใช้งานที่มีสถานะโดนระงับ"
              :value= "{
                text: statususer[3],
              }"
              :description="'<span class=\'text-sm font-weight-bolder text-dark\'>'+ ((statususer[3] / (statususer[2]+statususer[3])) * 100).toFixed(2) +'%</span> จากจำนวนทั้งหมด'"
              :icon="{
                component: 'fas fa-user-times',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
        <!-- model  -->
        <div class="modal fade" id="model-in" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">เพิ่มข้อมูลผู้ใช้งาน</b>
                        <button type="button" @click="closeModalIN()" class="close-btn">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 mb-3">
                          <div class="mb-3">
                              <label for="exampleFormControlInput1" class="form-label">ชื่อผู้ใช้งาน(Username)</label>
                              <input type="text" class="form-control" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="exampleFormControlInput1" placeholder="กรุณากรอกชื่อผู้ใช้งาน(Username)" v-model="users.username">
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInput2" class="form-label">รหัสผ่าน(Password)</label>
                              <input type="password" class="form-control" id="exampleFormControlInput2" placeholder="กรุณากรอกรหัสผ่าน" v-model="users.password">
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInput3" class="form-label">ชื่อ</label>
                              <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="กรุณากรอกชื่อ" v-model="users.name">
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInput4" class="form-label">ประเภทผู้ใช้งาน</label>
                              <select class="form-select" style="font-size: 18px;" id="exampleFormControlInput4" v-model="users.type">
                                <option value="">กรุณาเลือกประเภทผู้ใช้งาน</option>
                                <option value="1">แอดมิน</option>
                                <option value="2">ซุปเปอร์แอดมิน</option>
                              </select>
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInput5" class="form-label">สถานะ</label>
                              <select class="form-select" style="font-size: 18px;" id="exampleFormControlInput5" v-model="users.status">
                                <option value="">กรุณาเลือกสถานะ</option>
                                <option value="1">ใช้งานได้ปกติ</option>
                                <option value="2">โดนระงับการใช้งาน</option>
                              </select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                      <button type="button" @click="add_data()" class="btn btn-info border-radius-ptt" style="font-size: 20px;">บันทึกข้อมูล</button>
                        <button type="button" @click="closeModalIN()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- model edit  -->
        <div class="modal fade" id="model-ed" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <b class="text-ptt-26">แก้ไขข้อมูลผู้ใช้งาน</b>
                        <button type="button" @click="closeModalED()" class="close-btn">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="col-sm-12 mb-3">
                          <div class="mb-3">
                              <label for="exampleFormControlInpute1" class="form-label">ชื่อผู้ใช้งาน(Username)</label>
                              <input type="text" class="form-control" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="exampleFormControlInpute1" placeholder="กรุณากรอกชื่อผู้ใช้งาน(Username)" v-model="users_edit.username">
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInpute2" class="form-label">รหัสผ่าน(Password)</label>
                              <input type="password" class="form-control" id="exampleFormControlInpute2" placeholder="ในกรณีต้องการเปลี่ยนรหัสผ่านให้กรอกรหัสในช่อง แล้วกด บันทึกข้อมูล" v-model="users_edit.password">
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInpute3" class="form-label">ชื่อ</label>
                              <input type="text" class="form-control" id="exampleFormControlInpute3" placeholder="กรุณากรอกชื่อ" v-model="users_edit.name">
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInpute4" class="form-label">ประเภทผู้ใช้งาน</label>
                              <select class="form-select" style="font-size: 18px;" id="exampleFormControlInpute4" v-model="users_edit.type">
                                <option value="">กรุณาเลือกประเภทผู้ใช้งาน</option>
                                <option value="1">แอดมิน</option>
                                <option value="2">ซุปเปอร์แอดมิน</option>
                              </select>
                            </div>
                            <div class="mb-3">
                              <label for="exampleFormControlInpute5" class="form-label">สถานะ</label>
                              <select class="form-select" style="font-size: 18px;" id="exampleFormControlInpute5" v-model="users_edit.status">
                                <option value="">กรุณาเลือกสถานะ</option>
                                <option value="1">ใช้งานได้ปกติ</option>
                                <option value="2">โดนระงับการใช้งาน</option>
                              </select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                      <button type="button" @click="edit_data()" class="btn btn-info border-radius-ptt" style="font-size: 20px;">บันทึกข้อมูล</button>
                        <button type="button" @click="closeModalED()" class="btn btn-danger border-radius-ptt" style="font-size: 20px;">ปิด</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- table  -->
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg-0 mb-4">
            <div class="card">
              <div class="card-header text-center">
                <h5>ตารางข้อมูลผู้ใช้งานทั้งหมด</h5>
                <p>ผู้ใช้งานแบ่งออกเป็น 2 ระดับ คือ ซุปเปอร์แอดมินและแอดมิน(ในการลบข้อมูลสามารถคลิกที่ข้อมูลที่ต้องการลบได้พร้อมกันหลายๆข้อมูล)</p>
                <button class="btn btn-primary me-2" type="button" @click="openModalIN()">เพิ่มข้อมูล</button>
                <button class="btn btn-danger" type="button" @click="del_data()">ลบข้อมูล</button>
              </div>
              <div class="card-body">
                <DataTable :data="datauser" :options="options" class="table table-hover" width="100%" ref="dataselect">
                  <thead>
                      <tr>
                          <th>Hash ID</th>
                          <th>ชื่อ</th>
                          <th>ชื่อผู้ใช้(Username)</th>
                          <th>เพิ่มข้อมูลเมื่อ</th>
                          <th>สถานะ</th>
                          <th>ประเภทผู้ใช้งาน</th>
                          <th>จัดการ</th>
                      </tr>
                  </thead>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    </div>
  </div>
</template>

<style>
@import 'datatables.net-bs5';
@import 'datatables.net-buttons-bs5';
@import 'datatables.net-responsive-bs5';
@import 'datatables.net-select-bs5';
div.dt-container div.dt-length select {
    width: 4em;
}

table.dataTable th.dt-type-numeric {
  text-align: left;
}
table.dataTable td.dt-type-numeric {
  text-align: left;
}
.table-center {
  text-align: center;
}
div.dt-container div.dt-search input{
  width: 180px;
}
.pagination {
  justify-content: center !important;
}
@media only screen and (min-width: 768px) {
  .left-col {
      float: left;
      width: 40%;
  }
  .center-col {
      float: left;
      width: 25%;
  }
  .right-col {
      float: left;
      width: 35%;
  }
}
.page-link {
  width: 50px;
  height: 50px;
}
.close-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 26px;
}
</style>
